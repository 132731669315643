import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import Image from "../utils/use-gatsby-local-image.js"

import resource_list from "../../static/data/resource_list.json"

export default class Resources extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      categories:[],
      resources: []
    }
    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    this.setState({resources: resource_list});

  }

  handleChange(event) {
    const query = event.target.value;
    if(query.length < 3){
      this.setState({resources: resource_list});
      return;
    }
    let resources = this.state.resources.filter(res => {
      return res?.description?.toLowerCase().includes(query.toLowerCase()) || res?.category?.toLowerCase().includes(query.toLowerCase()) || res?.title?.toLowerCase().includes(query.toLowerCase())
    });

    this.setState({resources: resources });

  }

  render() {
    
    return (
      <>
        <Head title={"Resources"} />
        <Layout activePage="Resources">
          <h1 className="mb-2 md:mb-0">Resources</h1>
          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="mr-2 mb-4 md:w-1/3 md:mb-0">
              <div>
                Below are resources to help you be successful on your Digital
                Sabbath journey.
              </div>
            </div>
            { <div className="w-full md:w-2/3 flex flex-col">
              <input
                id="search"
                name="search"
                placeholder="Search for a resource"
                className="input-field w-full"
                onBlur={this.handleChange}
                onChange={this.handleChange}
              />
            </div> }
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-4">
            {this.state.resources?.map(({ title, description, image, link, category }, index) => {
              return (
                <a
                  className="shadow-md bg-white rounded-md"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Image
                    className="h-32"
                    fileName={image}
                    alt="Illustration of a person reading a book"
                  />
                  <div className="p-4">
                    <h2 className="font-bold text-primary mb-1 text-xl">
                      {title}
                    </h2>
                    <p>{description}</p>
                    <p className="mt-3 text-gray-500 italic capitalize">{category}</p>
                  </div>
                </a>
              )
            })}
          </div>
        </Layout>
      </>
    )
  }
}
